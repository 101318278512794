import styled from 'styled-components';

export const StartedBottomSecondary = styled.div`
  font-size: 1em;
  width: 100%;
  text-align: center;
  word-break: keep-all;
  line-height: 1.2;
  padding: 15px 0;
  margin-top: 5px;
`;
